<template>
	<div class="user-page__wrapper">
		<div class="user-page">
			<UserMenu />
			<div class="user-page__content">
				<h2>Attendees List</h2>
				<div class="tabs dcp__tabs">
					<ul>
						<li><router-link :to="{ name: 'Attendees', params: { activeFilter: 'all' } }">All</router-link></li>
						<li class="is-active">
							<router-link :to="{ name: 'Matchmaking' }" >Matchmaking</router-link>
						</li>
						<li><router-link :to="{ name: 'Attendees', params: { activeFilter: 'con' } }">My Connections</router-link></li>
						<li><router-link :to="{ name: 'Attendees', params: { activeFilter: 'req' } }">Pending Requests<span v-if="pendingCount > 0">&nbsp;({{ pendingCount }})</span></router-link></li>
					</ul>
				</div>

				<div class="attendees__wrapper">
					<div v-if="!loaded" class="loader-wrapper is-active has-min-height">
						<div class="loader is-loading"></div>
					</div>
					<div v-if="loaded && attendees.length === 0">
						<p class="has-text-centered">No attendees found</p>
					</div>

					<div class="attendees__list">
						<div v-for="attendee in attendees" :key="attendee.id" class="attendees__box">
							<div class="attendees__box-inner  attendees__box-inner--overlay" :class="{ 'request-sent': attendee.userType === 'outgoingRequest' }">
								<router-link class="image" :to="{ name: 'AttendeeDetail', params: { id: attendee.id } }">
									<img v-if="hasFrame(attendee)" :src="platformSettings.profileFrameImage | imageUrl" class="frame" />
									<inline-svg v-if="!attendee.image && attendee.gender === 'Male'" :src="require('../assets/user_male.svg')" width="25" />
									<inline-svg v-else-if="!attendee.image && attendee.gender === 'Female'" :src="require('../assets/user_female.svg')" width="25" />
									<inline-svg v-else-if="!attendee.image" :src="require('../assets/user_generic.svg')" width="25" />
									<img v-else :src="attendee.image | imageUrl('thumbnail')" />
								</router-link>
								<router-link :to="{ name: 'AttendeeDetail', params: { id: attendee.id } }"
									><strong>{{ attendee.firstName }} {{ attendee.lastName }}</strong></router-link
								>
								<span>{{ attendee.job_title }} <span v-if="attendee.job_title && attendee.company">at</span> {{ attendee.company }}</span>
								<div v-if="session.user.id !== attendee.id" class="attendees__actions">
									<a v-if="attendee.userType !== 'outgoingRequest'" class="has-tooltip-bottom has-tooltip-fade" data-tooltip="Connect" @click.prevent="sendFriendRequest(attendee)">
										<inline-svg :src="require('../assets/plus.svg')" width="20" />
									</a>
									<router-link :to="{ name: 'Chat', params: { user: attendee.id } }" class="has-tooltip-bottom has-tooltip-fade" data-tooltip="Send message">
										<inline-svg :src="require('../assets/send.svg')" width="20" />
									</router-link>
								</div>
							</div>
							<div v-if="attendee.userType === 'outgoingRequest'" class="attendees__status">
								<div class="attendees__status-info">
									<inline-svg :src="require('../assets/ok-circle.svg')" width="32" class="attendees__status-icon" />
									<p><strong>Request sent</strong></p>
								</div>
								<div class="attendees__actions">
									<router-link :to="{ name: 'Chat', params: { user: attendee.id } }" class="has-tooltip-bottom has-tooltip-fade" data-tooltip="Send message">
										<inline-svg :src="require('../assets/send.svg')" width="20" />
									</router-link>
								</div>
							</div>
							<div class="attendees__match">
								<p>
									Match: <strong>{{ userMatch(attendee.match) }}%</strong>
								</p>
							</div>
						</div>
					</div>
					<div class="attendees__footer">
						<p>All recommendations are based on your profile interests.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http';
	import UserMenu from '../components/UserMenu';
	import session from '../utils/session';
	import platformSettings from '../utils/platformSettings';

	export default {
		name: 'Matchmaking',
		components: { UserMenu },
		data() {
			return {
				title: '',
				description: '',
				background: '',
				attendees: [],
				loaded: false,
				emptyWarning: '',
				session: session.state,
				platformSettings: platformSettings.state,
				pendingCount: 0,
			};
		},
		computed: {
			showMatchmaking() {
				return this.platformSettings.matchmakingEnabled === true;
			},
		},
		mounted() {
			$http
				.get('/users-matches')
				.then(({ data }) => {
					this.setData(data);
				});

			this.loadPendingCount();
		},
		methods: {
			setData(data) {
				this.attendees = data;
				this.total = this.attendees.length;
				this.loaded = true;
			},
			loadPendingCount() {
				$http.get('/users/network?_start=0&_limit=51&pending_reqs=true').then((res) => {
					if (res.data && 'total' in res.data) {
						this.pendingCount = res.data.total;
					}
				});
			},
			hasFrame(attendee) {
				return (
					attendee.eaaci_membership === true && attendee.profile_frame_enabled === true && this.platformSettings.profileFrameEnabled && this.platformSettings.profileFrameImage
				);
			},
			sendFriendRequest(attendee) {
				if (attendee && attendee.id && this.session.user.id) {
					attendee.userType = 'outgoingRequest';

					$http
						.post('/friend-requests', {
							author: this.session.user.id,
							recipient: attendee.id,
						})
						.then(data => {
							this.$set(this.attendees, this.attendees.indexOf(attendee), attendee);
						});
				}
			},
			onPaginate(newPage) {
				this.page = newPage;
				this.doSearch();
			},
			userMatch(value) {
				const roundedMatch = Math.round(value * 100);

				if (roundedMatch >= 100) {
					return 100;
				} else if (roundedMatch <= 0) {
					return 0;
				} else {
					return roundedMatch;
				}
			},
		},
	};
</script>
